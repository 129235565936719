<template>
  <section
    class="
      processSection 
      pc:h-[380px] pc:py-[75px]
      tablet:h-[344px] tablet:py-18
      mobile:h-[284px] mobile:py-16
    "
    :style="[ $screen.breakpoint !== 'mobile' ? { backgroundImage: 'url(' + sectionBg + ')' } : { backgroundImage: 'url(' + sectionMobileBg + ')' } ]"
    data-aos="fade-up"
    data-aos-duration="900"
  >
    <div
      class="flex justify-center text-white pc:justify-start"
    >
      <div
        class="
        flex
        flex-col
        pc:max-w-[1064px]
        pc:min-w-[1064px]
        pc:items-start
        tablet:items-center
        mobile:items-center"
      >
        <p
          class="
            pc:max-w-[543px]
            pc:h-14 pc:text-[44px] pc:leading-[56px] pc:tracking-[-0.4px]
            tablet:h-[46px] tablet:text-[36px] tablet:leading-[46px] tablet:tracking-[-0.3px]
            mobile:h-8 mobile:text-[26px] mobile:leading-[32px] mobile:tracking-[-0.2px]
            font-bold font-Pretendard text-center
          "
          :style="'word-wrap: break-word; word-break: keep-all;'"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <span style="text-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);">우리의 가치에 공감한다면</span>
        </p>
        <p
          class="
            pc:max-w-[543px] tablet:max-w-[450px] mobile:max-w-[400px] 
            pc:h-[68px] pc:text-[20px] pc:leading-[34px] pc:tracking-[-0.2px] pc:text-left
            tablet:h-[58px] tablet:text-[18px] tablet:leading-[29px] tablet:tracking-[-0.1px]
            mobile:h-[44px] mobile:text-[14px] mobile:leading-[22px] mobile:tracking-[-0.1px] mobile:text-center
            mt-[10px] font-Pretendard text-gray-200
          "
          :style="'word-wrap: break-word; word-break: keep-all; text-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);'"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          게임으로 세상에 재미를 더하고 싶은 게임 제작자라면,
          <br />
          우리의 미션과 비전을 함께 달성해 나가요!
        </p>
        <CustomButton
          class="
            pc:mt-8
            tablet:mt-8
            mobile:mt-[24px]
            font-Pretendard
          "
          text="채용 중인 포지션 보기"
          :size="$screen.breakpoint === 'pc' ? '2xl' : 'xl'"
          rounded
          color="secondary"
          data-aos="fade-up"
          data-aos-duration="900"
          @click.native="$router.push('/careers')"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CustomButton from '../../../components/Button/customButton.vue';
export default {
  name: 'Hire',
  components: { CustomButton },
  data() {
    return {
      sectionBg: require('@/assets/culture/image-13.png'),
      sectionMobileBg: require('@/assets/culture/image-13.png'),
    };
  }
};
</script>

<style lang="scss" scoped>
.processSection{
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80%;
  :is(.pc) &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0; left: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 170%);
    width: 100%; height: 100%;
  }
  :is(.mobile, .tablet) &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0; left: 0;
    background-color: rgba(0, 0, 0, 0.55);
    width: 100%; height: 100%;
  }
  & > div{
    position: relative;
    z-index: 1;
  }
  .customBtn span{
    font-size: 20px;
    letter-spacing: -0.2px;
  }
}
.processSection__title{
  max-width: 890px;
  margin: 0 auto;
  font-size: 42px;
  line-height: normal;
  font-weight: bold;
  text-align: left;
  letter-spacing: -0.2px;
  color: #FFF;
  word-break: keep-all;
  :is(.tablet) &{
    text-align: center;
    max-width: 560px;
    word-break: keep-all;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -0.3px;
  }
  :is(.mobile) &{
    text-align: center;
    max-width: 340px;
    word-break: keep-all;
    font-size: 22px;
    line-height: 1.55;
    letter-spacing: -0.1px;
  }
}
.processSection__title span{
  transition: color 0.6s;
}
.processSection__title span.on{
  color: $primaryColor;
}
.sub__text{
  word-break: keep-all;
}
:is( .pc ) .processSection{
  & > div {
    width: 86%;
    margin: 0 auto;
    max-width: 1280px;
  }
  .sub__text{
    word-break: keep-all;
    font-size: 1rem;
    line-height: 2rem;
  }
}
:is( .tablet ) .processSection{
  .sub__text{
    word-break: keep-all;
    font-size: 1rem;
    line-height: 2rem;
  }
}
:is( .mobile ) .processSection{
  .sub__text{
    word-break: keep-all;
    font-size: 1rem;
    line-height: 2rem;
  }
}
:is( .mobile ) {
  .processSection{
    background-size: auto 100%;
    // padding: 95px 0;
  }
  .extraLarge__text span{
    padding-right: 0.8rem;
  }
  .customBtn span{
    font-size: 1rem;
    letter-spacing: -0.1px;
  }
}
:is( .tablet ) {
  .processSection{
    // padding: 93px 0;
  }
}
</style>
