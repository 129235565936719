<template>
  <div>
    <!-- <PageTopBanner :data="bannerInfo" /> -->
    <CookappsForFun />
    <Why />
    <ExpandedBanner />
    <Welfare />
    <Process />
    <!-- <JobPosting /> -->
  </div>
</template> 
<script> 
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
import CookappsForFun from './compnents/cookappsForFun.vue';
import Why from './compnents/why.vue';
import Welfare from './compnents/welfare.vue';
import Process from './compnents/process.vue';
// import JobPosting from './compnents/jobPosting.vue';
import ExpandedBanner from './compnents/expandedBanner.vue';
export default {
  name: 'HowWeWork',
  components: { 
    // PageTopBanner, 
    // JobPosting,
    CookappsForFun, Why, Welfare, Process, ExpandedBanner 
  },
  data() {
    return {
      bannerInfo: {
        title: 'How we work'
      },
    };
  },
};
</script> 
<style>
</style>